<template>
  <LayoutPanel>
    <div v-if="loadingLocal || carga" class="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-if="!carga" class="page">
      <h2 class="page__title">Beneficios</h2>
      <div class="col-12 px-2">
        <div class="divCard" v-if="usuario.role != 'admin'">
          <div class="msgInvalid">
            <font-awesome-icon :icon="['fas', 'user-slash']"></font-awesome-icon>
            <h3>Sin permisos</h3>
            <p>
              Su usuario no tiene permiso suficiente para ver este contenido.
            </p>
          </div>
        </div>
        <div class="divCard" v-if="usuario.role == 'admin'">
          <div class="col-12 px-0">
            <h3 class="divCard__title">Cargar Beneficio</h3>
            <div>
              <form @submit.prevent="uploadBeneficio()" class="d-flex flex-column">
                <div class="col-12 d-flex flex-wrap align-items-center">
                  <div class="form-group col-12 col-md-3">
                    <label for="name">Titulo</label>
                    <input
                      type="text"
                      class="form-control"
                      id="title"
                      v-model.trim="$v.title.$model"
                      :class="{'is-invalid': $v.title.$error}"
                      required
                    />
                    <small class="text-danger msg-error" v-if="!$v.title.maxLength">Máximo 40 carácteres</small>
                  </div>

                  <div class="form-group col-12 col-md-3">
                    <label for="category">Categoria</label>
                    <b-form-select
                      v-model.trim="$v.category.$model"
                      :options="categoryOptions"
                      id="category"
                      class="form-control"
                      required
                    ></b-form-select>
                  </div>
                  <div class="form-group col-12 col-md-3">
                    <label for="category">Beneficiarios</label>
                    <b-form-select v-model.trim="$v.to.$model" :options="toOptions" id="to" class="form-control" required></b-form-select>
                  </div>

                  <div class="col-12 col-md-3">
                    <input type="file" accept="image/*" :multiple="false" ref="boton" class="d-none" @change="buscarImagen($event)" />
                    <button class="btn btn-primary btn-block mb-3 mb-md-0" @click="$refs.boton.click()" type="button">Seleccionar imagen</button>
                  </div>
                </div>
                <div class="col-12 d-flex flex-wrap flex-md-nowrap ">
                  <div class="form-group col-12 col-md-6 ">
                    <label for="name">Detalle</label>
                    <textarea
                      type="text-area"
                      class="form-control"
                      id="detail"
                      v-model.trim="$v.detail.$model"
                      :class="{'is-invalid': $v.detail.$error}"
                      required
                    ></textarea>
                    <small class="text-danger msg-error" v-if="!$v.detail.maxLength">Máximo 250 carácteres</small>
                  </div>
                  <div class="col-12 col-md-3 pt-3">
                    <div :class="{'alert alert-success ': markState == true, 'alert alert-danger': markState == false}" role="alert">
                      <div class="custom-control custom-switch ">
                        <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="markState" />
                        <label class="custom-control-label" for="customSwitch1" v-if="markState">Permitir marcar en uso</label>
                        <label class="custom-control-label" for="customSwitch1" v-if="!markState">No permitir marcar en uso</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-3 pr-md-2 my-3">
                    <button type="submit" class="btn btn-primary btn-block mt-0" :class="{disabled: !desactivar}" :disabled="!desactivar">
                      Guardar
                    </button>
                  </div>
                </div>
                <div class="col-12 col-md-9 pl-md-4">
                  <b-alert show variant="primary" v-if="files !== null">
                    <p class="mb-0">Imagen seleccionada:</p>
                    <span>
                      <font-awesome-icon :icon="['fas', 'file-pdf']"></font-awesome-icon>
                      <span class="mr-3 ml-1">{{ fileName }}</span>
                    </span>
                  </b-alert>
                  <b-alert show variant="warning" v-if="files !== null">Hay cambios pendientes, debe hacer click en Guardar</b-alert>
                  <div
                    v-if="send"
                    :class="{'alert alert-success w-100 mt-3 mt-md-1 mb-0': !sendError, 'alert alert-danger w-100 mt-3 mt-md-1 mb-0': sendError}"
                    role="alert"
                  >
                    <p class="mb-0" v-if="sendError">Error al guardar los datos, vuelva a intentarlo en unos instantes</p>
                    <p class="mb-0" v-if="!sendError">Datos guardados exitosamente</p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="divCard" v-if="usuario.role == 'admin'">
          <div class="col-12 px-0">
            <h3 class="divCard__title">Lista de Beneficios</h3>
            <div>
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Titulo</th>
                    <th scope="col">Categoria</th>
                    <th scope="col">Beneficiarios</th>
                    <th scope="col" class="text-right pr-3">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in beneficiosArray" :key="index">
                    <td>{{ item.title }}</td>
                    <td>{{ item.category }}</td>
                    <td>{{ item.to }}</td>
                    <td class="text-right">
                      <button v-b-modal="'detail'" class="btn-see" @click="getDetail(item)">
                        <font-awesome-icon :icon="['fas', 'eye']"></font-awesome-icon>
                      </button>
                      <button class="btn-delete" @click="deleteBeneficio(item)">
                        <font-awesome-icon :icon="['fas', 'trash']"></font-awesome-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="detail" ok-only ok-title="Cerrar" title="Detalle" size="sm">
      {{ detailSelected }}
      <h3 class="title_bene">Socios que utilizan el beneficios: {{beneficiarios.length}}</h3>
      <ul>
        <li v-for="(user, idx) in beneficiarios" :key="idx">{{user.surname}}, {{user.name}}</li>
      </ul>
    </b-modal>
  </LayoutPanel>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {db, storage} from "../firebase";
import {required, email, sameAs, minLength, maxLength, numeric} from "vuelidate/lib/validators";
import LayoutPanel from "../components/LayoutPanel.vue";
export default {
  name: "Recibos",
  data() {
    return {
      beneficiarios:[],
      markState: false,
      beneficiosArray: [],
      title: "",
      detail: "",
      to: "",
      detailSelected: "",
      sendError: false,
      send: false,
      category: "",
      categoryOptions: [],
      toOptions: [
        {value: "todos", text: "Todos"},
        {value: "agec", text: "Socio Agec"},
        {value: "mutual", text: "Socio Mutual"},
      ],
      loadingLocal: false,
      files: null,
      fileName: null,
    };
  },
  validations: {
    category: {
      required,
    },
    to: {
      required,
    },
    detail: {
      required,
      maxLength: maxLength(250),
    },
    title: {
      required,
      maxLength: maxLength(40),
    },
  },
  components: {LayoutPanel},
  computed: {
    ...mapState(["usuario", "carga"]),
    desactivar() {
      return !this.$v.category.$invalid && !this.$v.to.$invalid && !this.$v.title.$invalid && !this.$v.detail.$invalid;
    },
  },
  methods: {
    buscarImagen(event) {
      this.send = false;
      this.files = [];
      this.files = event.target.files;
      this.fileName = event.target.files[0].name;
    },
    async getDetail(item) {
      this.detailSelected = "";
      this.beneficiarios = []
      this.detailSelected = item.detail;
      this.loadingLocal = true;
      if (this.usuario !== null) {
        await db
          .collection("users")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
              // let item = {id: doc.id, name: doc.data().name, surname: doc.data().surname, state: doc.data().state};
              await db
                .collection("users")
                .doc(doc.id)
                .collection("beneficios")
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((docU) => {
                    if (item.title === docU.data().name) {
                      this.beneficiarios.push({name: doc.data().name, surname: doc.data().surname})
                    }
                  });
                })
                .catch((error) => {});
            });
            console.log(this.beneficiarios, 'beneficiarios')
            this.loadingLocal = false;
          })
          .catch((error) => {
            this.loadingLocal = false;
          });
      }
    },
    async uploadBeneficio() {
      let urlTmp = "";
      this.loadingLocal = true;
      if (this.files === null) {
        this.loadingLocal = true;
        await db
          .collection("beneficios")
          .doc(this.title)
          .set({
            title: this.title,
            category: this.category,
            to: this.to,
            detail: this.detail,
            imageUrl: null,
            imageName: null,
            usable: this.markState,
          })
          .then(() => {
            this.category = "";
            this.to = "";
            this.detail = "";
            this.title = "";
            this.loadingLocal = false;
            this.send = true;
            this.markState = false;
            this.getBeneficios();
            this.sendError = false;
          })
          .catch((error) => {
            console.log(error);
            this.loadingLocal = false;
            this.sendError = true;
            this.send = true;
          });
      } else {
        try {
          for (let index = 0; index < this.files.length; index++) {
            const refImagen = storage
              .ref()
              .child("beneficios")
              .child(this.category)
              .child(this.files[index].name);
            const res = await refImagen.put(this.files[index]);
            urlTmp = await refImagen.getDownloadURL();
          }

          await db
            .collection("beneficios")
            .doc(this.title)
            .set({
              title: this.title,
              category: this.category,
              to: this.to,
              detail: this.detail,
              imageUrl: urlTmp,
              imageName: this.fileName,
              usable: this.markState,
            });
          this.category = "";
          this.to = "";
          this.detail = "";
          this.title = "";
          this.loadingLocal = false;
          this.send = true;
          this.getBeneficios();
          this.sendError = false;
          this.files = null;
          this.fileName = null;
          this.markState = false;
        } catch (error) {
          this.loadingLocal = false;
          this.sendError = true;
          this.send = true;
        }
      }

      // this.file = this.myCroppa.generateDataUrl()
    },

    async deleteBeneficio(item) {
      if (item.url === null) {
        db.collection("beneficios")
          .doc(item.title)
          .delete()
          .then(() => {
            this.beneficiosArray = [];
            this.getBeneficios();
          });
      } else {
        const desertRef = storage
          .ref()
          .child("beneficios")
          .child(item.category)
          .child(item.imageName);
        await desertRef
          .delete()
          .then(function() {
            console.log("lo borre");
            data();

            // this.loadingLocal = false;
          })
          .catch(function(error) {
            //  this.loadingLocal = false
          });
        db.collection("beneficios")
          .doc(item.title)
          .delete()
          .then(() => {
            this.beneficiosArray = [];
            this.getBeneficios();
          });
      }
    },
    getBeneficios() {
      this.beneficiosArray = [];
      this.loadingLocal = true;
      db.collection("beneficios")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // console.log(doc.id, ' =>', doc.data())
            let item = {
              title: doc.data().title,
              category: doc.data().category,
              to: doc.data().to,
              id: doc.id,
              detail: doc.data().detail,
              url: doc.data().imageUrl,
              imageName: doc.data().imageName,
            };
            this.beneficiosArray.push(item);
            this.loadingLocal = false;
          });
        })
        .catch((error) => {
          this.loadingLocal = false;
        });
      this.loadingLocal = false;
    },
    getCategories() {
      this.loadingLocal = true;
      db.collection("beneficios-category")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // console.log(doc.id, ' =>', doc.data())
            let item = {value: doc.data().value, text: doc.data().name};
            this.categoryOptions.push(item);
            this.loadingLocal = false;
          });
        })
        .catch((error) => {
          console.log(error);
          this.loadingLocal = false;
        });
      this.loadingLocal = false;
    },
  },
  created() {
    this.getBeneficios();
    this.getCategories();
  },
};
</script>

<style lang="scss">
@import "../scss/styles.scss";

.table {
  .thead-dark {
    th {
      font-weight: 600;
      font-size: 0.9rem;
      padding: 0.75rem 0.3rem;
    }
  }
  td {
    font-size: 0.9rem !important;
    padding: 0.75rem 0.3rem;
    vertical-align: middle;
    .img-table {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
  i {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: block;
    margin-left: 20px;
    margin-top: 5px;
    &.activo {
      background-color: $statusGreen;
    }
    &.inactivo {
      background-color: $statusRed;
    }
  }
}

.btn-edit {
  padding-right: 1.5rem;
  svg {
    font-size: 1.3rem;
  }
}

.btn-block {
  padding: 14px 35px !important;
}

.btn-delete {
  background-color: transparent;
  border: none;
  outline: none;
  padding-right: 1.5rem;
  svg {
    font-size: 1.3rem;
    color: $statusRed;
  }
}

.btn-see {
  background-color: transparent;
  border: none;
  outline: none;
  padding-right: 1.5rem;
  svg {
    font-size: 1.3rem;
    color: $secondaryColorHover;
  }
  &:hover {
    svg {
      color: $secondaryColorHover;
    }
  }
}

.title_bene{
  font-size: 1rem;
  font-weight: 600;
  margin-top: 1rem;
}
</style>
