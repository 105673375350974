<template>
  <LayoutPanel>
    <div v-if="loadingLocal || carga" class="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-if="!carga" class="page">
      <h2 class="page__title">Editar Recibos</h2>
      <div class="col-12 px-2">
        <div class="divCard" v-if="usuario.role != 'admin'">
          <div class="msgInvalid">
            <font-awesome-icon :icon="['fas', 'user-slash']"></font-awesome-icon>
            <h3>Sin permisos</h3>
            <p>
              Su usuario no tiene permiso suficiente para ver este contenido.
            </p>
          </div>
        </div>
        <div class="divCard" v-if="usuario.role == 'admin'">
          <div class="col-12 px-0">
            <h3 class="divCard__title">Lista de Recibos de {{ id }}</h3>
            <div>
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" class="text-center">#</th>
                    <th scope="col">Nombre del archivo</th>
                    <th scope="col" class="text-right">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(recibo, index) in recibosArray" :key="index">
                    <th class="text-center" scope="row">{{ (nro = index + 1) }}</th>
                    <td>{{ recibo }}</td>
                    <td class="text-right">
                      <button v-b-modal="'confirm-down'" class="btn-down" @click="downloadFile(recibo)">
                        <font-awesome-icon :icon="['fas', 'file-download']"></font-awesome-icon>
                      </button>
                      <button class="btn-delete" @click="deleteFile(recibo)">
                        <font-awesome-icon :icon="['fas', 'trash']"></font-awesome-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <b-modal id="confirm-down" ok-only ok-title="Cerrar" title="Confirmación" size="sm">
          ¿Esta seguro de que quiere descargar el recibo?
         
          <a :href="fileUrl" target="_blank" class="btn btn-success">Si, descargar</a>
        </b-modal>
      </div>
    </div>
  </LayoutPanel>
</template>

<script>
import axios from "axios";
import {mapState, mapActions} from "vuex";
import {db, storage} from "../firebase";
import LayoutPanel from "../components/LayoutPanel.vue";
export default {
  name: "RecibosEditar",
  data() {
    return {
      id: this.$route.params.id,
      recibosArray: [],
      sendError: false,
      send: false,
      loadingLocal: false,
      files: null,
      fileUrl: "",
    };
  },
  components: {LayoutPanel},
  computed: {
    ...mapState(["usuario", "carga"]),
  },
  methods: {
    async downloadFile(name) {
      // this.loadingLocal = true;
      let tmpfile = "";
      const desertRef = storage
        .ref()
        .child("recibos")
        .child(this.id)
        .child(name);
      await desertRef
        .getDownloadURL()
        .then(function(url) {
          tmpfile = url;

          var xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = function(event) {
            var blob = xhr.response;
            var src = url;
          };
          xhr.open("get", url, true);
          xhr.send();

          // this.loadingLocal = false;
        })
        .catch(function(error) {
        });
      this.fileUrl = tmpfile;
    },
    async deleteFile(name) {
      let data = this.getRecibos;
      let array = this.recibosArray;
      // this.loadingLocal = true;
      const desertRef = storage
        .ref()
        .child("recibos")
        .child(this.id)
        .child(name);
      await desertRef
        .delete()
        .then(function() {
          data();

          // this.loadingLocal = false;
        })
        .catch(function(error) {
          //  this.loadingLocal = false
        });
      db.collection("recibos")
        .doc(this.id)
        .update({
          number: array.length - 1,
        });
    },
    async getRecibos() {
      this.loadingLocal = true;
      let array = [];
      const refFiles = storage
        .ref()
        .child("recibos")
        .child(this.id);
      await refFiles
        .listAll()
        .then((querySnapshot) => {
          querySnapshot.items.forEach(function(itemRef) {
            array.push(itemRef.name);
          });
          this.recibosArray = array;
          this.loadingLocal = false;
        })
        .catch(function(error) {
          this.loadingLocal = false;
        });
    },
  },
  created() {
    this.getRecibos();
  },
};
</script>

<style lang="scss">
@import "../scss/styles.scss";

.table {
  .thead-dark {
    th {
      font-weight: 600;
      font-size: 0.9rem;
      padding: 0.75rem 0.3rem;
    }
  }
  td {
    font-size: 0.9rem !important;
    padding: 0.75rem 0.3rem;
  }
  i {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: block;
    margin-left: 20px;
    margin-top: 5px;
    &.activo {
      background-color: $statusGreen;
    }
    &.inactivo {
      background-color: $statusRed;
    }
  }
}

.btn-delete {
  background-color: transparent;
  border: none;
  outline: none;
  padding-right: 1.5rem;
  svg {
    font-size: 1.3rem;
    color: $statusRed;
  }
}
.btn-down {
  background-color: transparent;
  border: none;
  outline: none;
  padding-right: 1.5rem;
  svg {
    font-size: 1.3rem;
    color: $primaryColor;
  }
}

.btn-block {
  padding: 14px 35px !important;
}
.modal-body{
  text-align: center;
}
.modal-footer{
  justify-content: center;
}
</style>
