<template>
  <LayoutPanel>
    <div v-if="loadingLocal || carga" class="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-if="!carga" class="page">
      <h2 class="page__title">Usuarios</h2>
      <div class="col-12 px-2">
        <div class="divCard" v-if="usuario.role != 'admin'">
          <div class="msgInvalid">
            <font-awesome-icon :icon="['fas', 'user-slash']"></font-awesome-icon>
            <h3>Sin permisos</h3>
            <p>
              Su usuario no tiene permiso suficiente para ver este contenido.
            </p>
          </div>
        </div>
        <div class="divCard" v-if="usuario.role == 'admin'">
          <div class="d-flex justify-content-end w-100">
            <a :href="urlFile" download="usuarios.csv" class="btn-success btn mr-3" v-if="urlFile !== null">Descargar</a>
            <button class="btn-primary btn" @click="generateFile()">Generar archivo</button>
          </div>
          <div class="col-12 px-0">
            <h3 class="divCard__title">Lista de usuarios</h3>
            <div>
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" class="text-center">#</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Nombre y Apellido</th>
                    <th scope="col" class="text-right">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, index) in usersArray" :key="index">
                    <th class="text-center" scope="row">{{ index }}</th>
                    <td><i :class="user.state"></i></td>
                    <td>{{ user.surname }}, {{ user.name }}</td>
                    <td class="text-right">
                      <button v-b-modal="'userModal'" class="btn-see" @click="getUser(user)">
                        <font-awesome-icon :icon="['fas', 'eye']"></font-awesome-icon>
                      </button>
                      <router-link :to="{name: 'Editar', params: {id: user.id}}" class="btn-edit"
                        ><font-awesome-icon :icon="['fas', 'pencil-alt']"></font-awesome-icon>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <b-modal id="userModal" ok-only ok-title="Cerrar" title="Datos del usuario" size="lg">
          <div class="modal-data">
            <div class="modal-data__profile">
              <div class="profile-picture pt-3">
                <img v-if="profileFoto == ''" src="../assets/avatar.png" alt="perfil" class="profile-picture__img" />
                <img v-if="profileFoto != ''" :src="profileFoto" alt="perfil" class="profile-picture__img" />
              </div>
              <div class="profile-info pt-0">
                <h3 class="divCard__title">Datos de perfil</h3>
                <p><b>Nombre:</b> {{ profileSurname }}, {{ profileName }}</p>
                <p><b>Email:</b> {{ profileEmail }}</p>
                <p><b>Socio N°:</b> {{ profileNumber }}</p>
                <p><b>Estado:</b> {{ profileState }}</p>
                <p><b>Domicilio:</b> {{ profileAddress }}</p>
                <p><b>Telefono:</b> {{ profilePhone }}</p>
              </div>
            </div>
            <div class="d-flex flex-wrap flex-md-nowrap">
              <div class="modal-data__employee" v-if="profileEmployer === 'agec' || profileEmployer === 'mutual'">
                <h3 class="divCard__title">Datos de empleado</h3>
                <p><b>Empelador:</b> {{ profileEmployer }}</p>
                <p><b>Legajo:</b> {{ profileLegajo }}</p>
                <p><b>Ingreso:</b> {{ profileIngreso }}</p>
                <p><b>Egreso:</b> {{ profileEgreso }}</p>
                <p><b>Modalidad:</b> {{ profileModality }}</p>
                <p><b>Categoria:</b> {{ profileCategory }}</p>
              </div>
              <div class="modal-data__family">
                <h3 class="divCard__title">Datos de familia</h3>
                <p><b>Estado Civil:</b> {{ profileCivilState }}</p>
                <p><b>Hijos:</b> {{ profileChilds }}</p>
                <div v-for="(child, index) in profileChildsArray" :key="index" class="pb-3">
                  <p class="mb-0" v-if="child.name !== ''">{{ child.name }}</p>
                  <small v-if="child.date !== ''">Nacimiento: {{ child.date }}</small>
                </div>
              </div>
            </div>
            <div>
              <h3 class="divCard__title">beneficios en uso:</h3>
              <p>{{ profileBeneficios }}</p>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </LayoutPanel>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {db} from "../firebase";
import LayoutPanel from "../components/LayoutPanel.vue";
export default {
  name: "Users",
  data() {
    return {
      urlFile: null,
      usersArray: [],
      loadingLocal: false,
      profileName: "",
      profileSurname: "",
      profileEmail: "",
      profileState: "",
      profileDni: "",
      profilePhone: "",
      profileNumber: "",
      profileRole: "",
      profileEmployer: "",
      profileLegajo: "",
      profileIngreso: "",
      profileEgreso: "",
      profileModality: "",
      profileCategory: "",
      profileDate: "",
      profileFoto: "",
      profileAddress: "",
      profilePhone: "",
      profileCivilState: "",
      profileChilds: "",
      profileChildsArray: "",
      profileBeneficios: "",
    };
  },
  components: {LayoutPanel},
  computed: {
    ...mapState(["usuario", "carga"]),
  },
  methods: {
    async generateFile() {
      this.loadingLocal = true;
      let date = new Date();
      let dateTmp = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + "-" + date.getSeconds();
      var emptyFormData = new FormData();
      emptyFormData.append("date", dateTmp);
      fetch("https://mutualmercantil.ar/emptyFile.php", {
        method: "POST",
        body: emptyFormData,
      });
      if (this.usuario !== null) {
        await db
          .collection("users")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
              // let item = {id: doc.id, name: doc.data().name, surname: doc.data().surname, state: doc.data().state};
              let beneficiosTmp = "";
              var formData = new FormData();
              formData.append("date", dateTmp);
              formData.append("nombre", doc.data().name);
              formData.append("apellido", doc.data().surname);
              formData.append("dni", doc.data().dni);
              formData.append("mail", doc.data().mail);
              formData.append("address", doc.data().address);
              formData.append("phone", doc.data().phone);
              formData.append("role", doc.data().role);
              formData.append("nro", doc.data().number);
              formData.append("empleador", doc.data().employer);
              formData.append("legajo", doc.data().legajo);
              formData.append("ingreso", doc.data().ingreso);
              formData.append("egreso", doc.data().egreso);
              formData.append("modality", doc.data().modality);
              formData.append("category", doc.data().category);
              formData.append("civilState", doc.data().civilState);
              formData.append("childs", doc.data().childs);
              formData.append("childsArray", doc.data().childsArray);

              await db
                .collection("users")
                .doc(doc.id)
                .collection("beneficios")
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((docU) => {
                    if (docU.exists) {
                      beneficiosTmp = beneficiosTmp + ", " + docU.data().name;
                    } else {
                      console.log("hola");
                    }
                  });
                  formData.append("beneficios", beneficiosTmp);
                })
                .catch((error) => {});
              fetch("https://mutualmercantil.ar/file.php", {
                method: "POST",
                body: formData,
              });
            });
            this.urlFile = "https://mutualmercantil.ar/datos-" + dateTmp + ".csv";
            
          })
          .catch((error) => {
            this.loadingLocal = false;
          });
          this.loadingLocal = false;
      }
    },
    async getUser(user) {
      this.loadingLocal = true;
      this.profileName = "";
      this.profileSurname = "";
      this.profileEmail = "";
      this.profileState = "";
      this.profileDni = "";
      this.profilePhone = "";
      this.profileNumber = "";
      this.profileRole = "";
      this.profileEmployer = "";
      this.profileLegajo = "";
      this.profileIngreso = "";
      this.profileEgreso = "";
      this.profileModality = "";
      this.profileCategory = "";
      this.profileDate = "";
      this.profileFoto = "";
      this.profileAddress = "";
      this.profilePhone = "";
      this.profileChildsArray = "";
      this.profileBeneficios = "";
      if (this.user !== null) {
        db.collection("users")
          .doc(user.id)
          .get()
          .then(async (doc) => {
            let beneficiosTmp = "";
            await db
              .collection("users")
              .doc(doc.id)
              .collection("beneficios")
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((docU) => {
                  if (docU.exists) {
                    beneficiosTmp = beneficiosTmp + " - " + docU.data().name;
                  }
                });
              })
              .catch((error) => {});
            if (doc.exists) {
              const profileTemp = {
                email: doc.data().mail,
                uid: this.$route.params.id,
                name: doc.data().name,
                surname: doc.data().surname,
                role: doc.data().role,
                number: doc.data().number,
                state: doc.data().state,
                dni: doc.data().dni,
                phone: doc.data().phone,
                address: doc.data().address,
                employer: doc.data().employer,
                date: doc.data().date,
                legajo: doc.data().legajo,
                modality: doc.data().modality,
                ingreso: doc.data().ingreso,
                egreso: doc.data().egreso,
                category: doc.data().category,
                foto: doc.data().foto,
                address: doc.data().address,
                phone: doc.data().phone,
                civilState: doc.data().civilState,
                childs: doc.data().childs,
                childsArray: doc.data().childsArray,
                beneficiosArray: beneficiosTmp,
              };
              this.profileEmail = profileTemp.email;
              this.profileSurname = profileTemp.surname;
              this.profileRole = profileTemp.role;
              this.profileNumber = profileTemp.number;
              this.profileDni = profileTemp.dni;
              this.profileName = profileTemp.name;
              this.profilePhone = profileTemp.phone;
              this.profileLegajo = profileTemp.legajo;
              this.profileEmployer = profileTemp.employer;
              this.profileModality = profileTemp.modality;
              this.profileIngreso = profileTemp.ingreso;
              this.profileEgreso = profileTemp.egreso;
              this.profileCategory = profileTemp.category;
              this.profileDate = profileTemp.date;
              this.profileFoto = profileTemp.foto;
              this.profileAddress = profileTemp.address;
              this.profilePhone = profileTemp.phone;
              this.profileState = profileTemp.state;
              this.profileCivilState = profileTemp.civilState;
              this.profileChilds = profileTemp.childs;
              this.profileChildsArray = profileTemp.childsArray;
              this.profileBeneficios = profileTemp.beneficiosArray;
              this.loadingLocal = false;
            } else {
              this.loadingLocal = false;
            }
          })
          .catch((error) => {
            this.loadingLocal = false;
          });
      }
    },
    getUsers() {
      this.loadingLocal = true;
      if (this.usuario !== null) {
        db.collection("users")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let item = {id: doc.id, name: doc.data().name, surname: doc.data().surname, state: doc.data().state};
              this.usersArray.push(item);
              this.loadingLocal = false;
            });
          })
          .catch((error) => {
            this.loadingLocal = false;
          });
      }
    },
  },
  created() {
    this.getUsers();
  },
};
</script>

<style lang="scss">
@import "../scss/styles.scss";

.table {
  .thead-dark {
    th {
      font-weight: 600;
      font-size: 0.9rem;
      padding: 0.75rem 0.3rem;
    }
  }
  td {
    font-size: 0.9rem !important;
    padding: 0.75rem 0.3rem;
  }
  i {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: block;
    margin-left: 20px;
    margin-top: 5px;
    &.activo {
      background-color: $statusGreen;
    }
    &.inactivo {
      background-color: $statusRed;
    }
  }
}

.btn-edit {
  padding-right: 1.5rem;
  svg {
    font-size: 1.3rem;
  }
}
.btn-see {
  background-color: transparent;
  border: none;
  outline: none;
  padding-right: 1.5rem;
  svg {
    font-size: 1.3rem;
    color: $secondaryColorHover;
  }
  &:hover {
    svg {
      color: $secondaryColorHover;
    }
  }
}

.modal {
  .modal-data {
    display: flex;
    flex-direction: column;
    &__profile {
      display: flex;
      flex-wrap: wrap;
      .profile-picture {
        width: 100%;
        height: auto;
        position: relative;
        &__img {
          width: 100%;
          height: 100%;
        }
      }
      .profile-info {
        padding: 1rem;
        width: 100%;
      }
    }
    &__employee,
    &__family {
      width: 100%;
    }
  }
}

@media screen and (min-width: 768px) {
  .modal {
    .modal-data {
      &__profile {
        flex-wrap: nowrap;
        .profile-picture {
          width: 250px;
          height: 250px;
          &__img {
            width: 100%;
            height: 100%;
          }
        }
        .profile-info {
          width: auto;
        }
      }
      &__employee,
      &__family {
        width: 50%;
      }
    }
  }
}
</style>
