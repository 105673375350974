<template>
  <div class="sidebar">
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <li class="nav-item">
            <router-link to="/mi-cuenta"><font-awesome-icon :icon="['fas', 'address-card']"></font-awesome-icon> Mi Cuenta</router-link>
          </li>
          <li class="nav-item" v-if="usuario.role === 'employee' && usuario.employer === 'mutual' && usuario.state === 'activo'">
            <router-link to="/mi-recibo"><font-awesome-icon :icon="['fas', 'money-check-alt']"></font-awesome-icon> Mi Recibo</router-link>
          </li>
          <li class="nav-item" v-if="usuario.role == 'admin'">
            <router-link to="/recibos"><font-awesome-icon :icon="['fas', 'money-check-alt']"></font-awesome-icon> Recibos</router-link>
          </li>
          <li class="nav-item" v-if="usuario.role == 'admin'">
            <router-link to="/usuarios"><font-awesome-icon :icon="['fas', 'user']"></font-awesome-icon> Usuarios</router-link>
          </li>
          <!-- <li class="nav-item" v-if="usuario.role == 'admin'">
            <router-link to="/novedades"><font-awesome-icon :icon="['fas', 'newspaper']"></font-awesome-icon> Novedades</router-link>
          </li> -->
          <li class="nav-item" v-if="usuario.role !== 'admin' && usuario.state === 'activo'">
            <router-link to="/mis-beneficios"><font-awesome-icon :icon="['fas', 'gifts']"></font-awesome-icon> Mis Beneficios</router-link>
          </li>
          <li class="nav-item" v-if="usuario.role == 'admin'">
            <router-link to="/beneficios"><font-awesome-icon :icon="['fas', 'gifts']"></font-awesome-icon> Beneficios</router-link>
          </li>
          <li class="nav-item" v-if="usuario.role == 'admin'">
            <router-link to="/notificaciones"><font-awesome-icon :icon="['fas', 'bell']"></font-awesome-icon> Notificaciones</router-link>
          </li>
          <li class="nav-item" v-if="usuario.role == 'admin'">
            <a href="https://mail.hostinger.com/" target="_blank"><font-awesome-icon :icon="['fas', 'envelope']"></font-awesome-icon> Webmail</a>
          </li>
          <li class="nav-item">
            <router-link to="/configuracion"><font-awesome-icon :icon="['fas', 'cog']"></font-awesome-icon> Configuracion</router-link>
          </li>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
export default {
  name: "Sidebar",
  computed: {
    ...mapState(["usuario"]),
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";

.sidebar {
  position: absolute;
  left: 0;
  top: 60px;
  z-index: 500;
  width: 250px;
  // height: calc(100vh - 60px);
  .navbar {
    padding: 0;
    .navbar-toggler {
      margin-top: -60px;
      margin-left: 15px;
      color: rgba(255, 255, 255, 1);
      border-color: rgba(255, 255, 255, 1);
    }
    .navbar-collapse {
      .navbar-nav {
        padding: 1rem;
        height: calc(100vh - 60px);
        .nav-item {
          padding: 0.5rem 1rem;
          .disabled {
            color: $gray;
            cursor: initial;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .sidebar {
    width: 250px;
    height: calc(100vh - 60px);
    .navbar {
      height: 100%;
      align-items: flex-start;
      .navbar-collapse {
        .navbar-nav {
          flex-direction: column !important;
        }
      }
    }
  }
}
</style>
